/// <reference path="../../../typings/jquery/jquery.d.ts"/>
/// <reference path="../../../typings/materialize-css/materialize-css.d.ts"/>

class SitterDashboard {
    private lv: LifeVine;
    private page: JQuery;
    private dashboard: JQuery;
    private myList: JQuery;
    private availableList: JQuery;
    private view: JQuery;

    private assignmentId: number|null;
    private bookingId: number|null;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.page = jQuery('#page-sitter-dashboard');
        this.dashboard = this.page.find('.dashboard');
        this.myList = this.dashboard.find('.my-bookings');
        this.availableList = this.dashboard.find('.available-bookings');
        this.view = this.page.find('.booking_viewer');

        this.myList.on('click', 'li', event => {
            event.preventDefault();
            event.stopPropagation();
            let $target = jQuery(event.target);
            location.hash = '#page-sitter-dashboard:assignment:' + $target.closest('li').attr('data-id');
        });

        this.availableList.on('click', 'li', event => {
            event.preventDefault();
            event.stopPropagation();
            let $target = jQuery(event.target);
            location.hash = '#page-sitter-dashboard:booking:' + $target.closest('li').attr('data-id');
        });

        this.view.on('click', '.confirm-booking', event => this.confirmBooking(event));
        this.view.on('click', '.decline-booking', event => this.declineBooking(event));
        this.view.on('click', '.cancel-booking', event => this.cancelBooking(event));
    }

    private confirmBooking(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (confirm('Confirm your assignment to this booking.')) {
            let promise: JQueryXHR;
            let message = '';
            if (this.assignmentId) {
                promise = this.lv.account().assignments().save(this.assignmentId, {status: 1});
            } else {
                promise = this.lv.account().assignments().create({booking_id: this.bookingId, status: 1});
            }
            promise
                .done(() => {
                    Materialize.toast('Booking has been confirmed', 2000);
                    location.hash = '#page-sitter-dashboard';
                })
                .fail((xhr) => {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                });
        }
    }

    private declineBooking(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (confirm('Decline your assignment to this booking.')) {
            let promise: JQueryXHR;
            this.lv.account().assignments().save(this.assignmentId, {status: 2})
                .done(() => {
                    Materialize.toast('Booking has been declined', 2000);
                    location.hash = '#page-sitter-dashboard';
                })
                .fail((xhr) => {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                });
        }
    }

    private cancelBooking(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (confirm('Cancel your assignment to this booking.')) {
            let promise: JQueryXHR;
            this.lv.account().assignments().save(this.assignmentId, {status: 3})
                .done(() => {
                    Materialize.toast('Booking has been cancelled', 2000);
                    location.hash = '#page-sitter-dashboard';
                })
                .fail((xhr) => {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                });
        }
    }

    public index(options?: LifeVineBookingsOptions) {
        this.page.attr('data-mode', 'index');
        this.lv.bookings().get()
            .done(bookings => {
                this.fillAvailableList(bookings);
            });

        this.lv.account().assignments().get()
            .done(assignments => {
                this.fillMyList(assignments);
            });
    }

    public showAssignment(assignment_id) {
        this.page.attr('data-mode', 'show');
        this.assignmentId = assignment_id;
        this.bookingId = null;
        this.lv.account().assignments().get(assignment_id)
            .done(assignment => this.fillViewer(assignment.booking, assignment));
    }

    public showBooking(booking_id) {
        this.page.attr('data-mode', 'show');
        this.assignmentId = null;
        this.bookingId = booking_id;
        this.lv.bookings().get(booking_id)
            .done(booking => this.fillViewer(booking));
    }

    private fillMyList(assignments) {
        this.myList.html('');
        jQuery.each(assignments, (idx, assignment) => {
            this.myList.append(SitterDashboard.createAssignmentListItem(assignment));
        });
    }

    private fillAvailableList(bookings) {
        this.availableList.html('');
        jQuery.each(bookings, (idx, booking) => {
            this.availableList.append(SitterDashboard.createBookingListItem(booking));
        });
    }

    private fillViewer(booking, assignment?) {
        let location = booking.location;
        let time = booking.duration_h < 24 ?
            booking.start_time.split(' ')[0] + '<br>'
            + booking.start_time.split(' ')[1] + ' - ' + booking.end_time.split(' ')[1] :
            booking.start_time + ' - ' + booking.end_time;
        let address = location.line1 + '<br>'
            + (location.line2 ? location.line2 + '<br>' : '')
            + location.city + ', ' + location.state + ' ' + location.zip;

        let clientCode = '';
        let client = booking.client ? booking.client : assignment.client;
        if (client) {
            clientCode = `
<div class="row">
    <div class="col s4">FOR</div>
    <div class="col s8">${client.first_name} ${client.last_name}</div>
</div>
`.trim();
        }

        let dependents = '';
        jQuery.each(booking.dependents, (idx, dependent) => {
            dependents = dependents + `
<li>${dependent.first_name} ${dependent.last_name}<span class="user-admin">&nbsp;(${dependent.age})</span></li>
`.trim();
        });

        let content = `
<span class="card-title">Booking</span>
${clientCode}
<div class="row">
    <div class="col s4">WHERE</div>
    <div class="col s8">${address}</div>
</div>
<div class="row">
    <div class="col s4">WHEN</div>
    <div class="col s8">${time}</div>
</div>
<div class="row">
    <div class="col s4">WHO</div>
    <div class="col s8"><ul>${dependents}</ul></div>
</div>
<!--div class="row">
    <div class="col s4">ESTIMATE</div>
    <div class="col s8">$${booking.price_estimate} for ${booking.duration_h} hrs. with ${booking.dependent_ids.length} chn.</div>
</div-->
<div class="row">
    <div class="col s4">STATUS</div>
    <div class="col s8"><span style="text-transform: capitalize">${booking.status_text}</span></div>
</div>
<div class="row">
    <div class="col s4">NOTES</div>
    <div class="col s8">${booking.notes}</div>
</div>
<div class="row">
    <div class="col s4">STAFF NOTES</div>
    <div class="col s8">${booking.staff_notes || ''}</div>
</div>
`.trim();

        let buttons = [];
        if (booking.status !== 0) { // Make sure booking isn't cancelled
            if (
                (assignment && assignment.status_text === 'requested')
                ||
                (assignment === undefined && booking.status_text === 'available')
            ) {
                buttons.push('<button type="button" class="waves-effect btn btn-large red confirm-booking">Confirm</button>');
                if (assignment && assignment.status_text === 'requested') {
                    buttons.push('<button type="button" class="waves-effect btn btn-large red decline-booking">Decline</button>');
                }
            } else if (assignment && assignment.status_text === 'confirmed') {
                buttons.push('<button type="button" class="waves-effect btn btn-large red cancel-booking">Cancel</button>');
            }
        }

        if (buttons.length > 0) {
            content += `
<div class="row">
    <div class="col s12 center-align">
        <br>
        ${buttons.join(' ')}
    </div>
</div>
`.trim();
        }

        this.view.find('.card-content').html(content).attr('data-status', booking.status_text);
    }


    private static createAssignmentListItem(assignment) {
        let booking = assignment.booking;
        let client = assignment.client;
        let color = '';
        if (assignment.status_text === 'requested') {
            color = 'yellow lighten-2';
        } else if (assignment.status_text === 'confirmed') {
            color = 'green accent-3';
        }

        let content = '' +
            `<i class="material-icons circle ${color}">event_note</i>` +
            `<span class="title">${booking.start_time}</span>` +
            `<p>${booking.duration_h} hrs with ${booking.dependents} chn for ${client.first_name} ${client.last_name}</p>`;
        return jQuery('<li class="collection-item avatar"></li>')
            .append(content)
            .addClass('status-' + booking.status_text)
            .attr('data-id', assignment.id);
    }

    private static createBookingListItem(booking) {
        let client = booking.client;
        let color = '';
        if (booking.status_text === 'available') {
            color = 'blue lighten-2';
        } else if (booking.status_text === 'pending') {
            color = 'yellow lighten-2';
        } else if (booking.status_text === 'confirmed') {
            color = 'green accent-3';
        }

        let content = '' +
            `<i class="material-icons circle ${color}">event_note</i>` +
            `<span class="title">${booking.start_time}</span>` +
            `<p>${booking.duration_h} hrs with ${booking.dependents} chn for ${client.first_name} ${client.last_name}</p>`;
        return jQuery('<li class="collection-item avatar"></li>')
            .append(content)
            .addClass('status-' + booking.status_text)
            .attr('data-id', booking.id);
    }
}

window.app.page('page-sitter-dashboard', () => {
    return params => {
        if (!window.controllers.sitterDashboard) {
            window.controllers.sitterDashboard = new SitterDashboard(window.lifeVine);
        }
        let controller = window.controllers.sitterDashboard;

        if (params === undefined) { // client list
            controller.index();
            return;
        }

        let parts = params.split(':');

        if (parts.length === 1) {
            if (parts[1] === 'completed') { // admin list
                controller.index({status: [5]});
                return;
            }
        }

        if (parts.length === 2) { // client show
            if (parts[0] === 'booking') {
                controller.showBooking(parts[1]);
                return;
            }

            if (parts[0] === 'assignment') {
                controller.showAssignment(parts[1]);
                return;
            }
        }

        if (parts.length === 3) {
            return;
        }

        Materialize.toast('Could not find that page', 2000);
        location.hash = '#page-sitter-dashboard';
    };
});